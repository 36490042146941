.auth-services-btns>* {
    margin: 0 3px;
}

.auth-button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;
}

.auth-button:hover:not(:disabled) {
    opacity: 0.8;
    cursor: pointer;
}

.auth-button:disabled {
    opacity: 0.6;
}

.auth-button-yandex {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiByeD0iNiIgZmlsbD0iI0ZDM0YxRCIvPgo8cGF0aCBkPSJNMjMuMDc2MiAzMkgyN1Y5SDIxLjI4ODFDMTUuNTQzIDkgMTIuNTI5OCAxMi4wMDU5IDEyLjUyOTggMTYuNDQ3MUMxMi41Mjk4IDIwLjI4MDUgMTQuNDgzNCAyMi40NDIgMTcuOTQzNyAyNC43ODkzTDIwLjA0NjQgMjYuMjA3OEwxNy4zNDc3IDIzLjg5NDNMMTIgMzJIMTYuMjU1TDIxLjk2NjkgMjMuMjg2M0wxOS45ODAxIDIxLjkzNTRDMTcuNTc5NSAyMC4yODA1IDE2LjQwNCAxOC45OTcxIDE2LjQwNCAxNi4yMTA3QzE2LjQwNCAxMy43NjIxIDE4LjA5MjcgMTIuMTA3MiAyMS4zMDQ2IDEyLjEwNzJIMjMuMDU5NlYzMkgyMy4wNzYyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+") no-repeat center;
}

.auth-button-vkontakte {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiByeD0iNiIgZmlsbD0iIzAwNzdGRiIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEwLjU0NjUgMTAuNTQ2NUM5IDEyLjA5MyA5IDE0LjU4MiA5IDE5LjU2VjIwLjQ0QzkgMjUuNDE4IDkgMjcuOTA3IDEwLjU0NjUgMjkuNDUzNUMxMi4wOTMgMzEgMTQuNTgyIDMxIDE5LjU2IDMxSDIwLjQ0QzI1LjQxOCAzMSAyNy45MDcgMzEgMjkuNDUzNSAyOS40NTM1QzMxIDI3LjkwNyAzMSAyNS40MTggMzEgMjAuNDRWMTkuNTZDMzEgMTQuNTgyIDMxIDEyLjA5MyAyOS40NTM1IDEwLjU0NjVDMjcuOTA3IDkgMjUuNDE4IDkgMjAuNDQgOUgxOS41NkMxNC41ODIgOSAxMi4wOTMgOSAxMC41NDY1IDEwLjU0NjVaTTEyLjggMTYuMTVDMTIuOTE4IDIxLjg3MjggMTUuOTI5NSAyNS4zMSAyMC44OTMyIDI1LjMxSDIxLjE4MTJWMjIuMDVDMjIuOTg5IDIyLjIzMzIgMjQuMzM3MSAyMy41NjgyIDI0Ljg4NzUgMjUuMzFIMjcuNDkzOUMyNi43ODY5IDIyLjcwNDQgMjQuOTUzNSAyMS4yNjA0IDIzLjgxNDEgMjAuNzFDMjQuOTUyNiAyMC4wMjkzIDI2LjU2NDEgMTguMzg5MyAyNi45NDM2IDE2LjE1SDI0LjU3MjJDMjQuMDc0NyAxNy45NzEgMjIuNTk0NSAxOS42MjMzIDIxLjE4MDMgMTkuNzhWMTYuMTVIMTguNzcxMVYyMi41QzE3LjMwNSAyMi4xMzM3IDE1LjM5MjQgMjAuMzUzOCAxNS4zMTQgMTYuMTVIMTIuOFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=") no-repeat center;
}