.profile-avatar div.ant-upload {
    margin: 0 !important;
    padding: 1px !important;
    height: 200px !important;
    width: 200px !important;
}

.profile-avatar img {
    border-radius: 8px !important;
}

.profile-avatar .ant-upload-list-item-container .ant-upload-list-item-progress {
    width: calc(100% - 4px) !important;
}

.profile-integrations-item {
    text-align: left;
    cursor: default;
}

.profile-integrations-item h4 {
    margin: 0;
}