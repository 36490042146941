.crm-menu-point,
.crm-menu-part {
    color: #1f1f30;
    font-weight: 700;
    text-decoration: none;
    grid-gap: 8px;
    align-items: center;
    border-radius: 8px;
    box-shadow: none;
    color: #41415a;
    display: grid;
    font-size: 15px;
    font-weight: 500;
    grid-template-columns: 24px 1fr;
    line-height: 36px;
    overflow: hidden;
    padding: 0 12px;
}

.crm-menu-part {
    /* margin-top: .5rem; */
    cursor: default;
}

.crm-menu-point>.menu-icon {
    display: grid;
    justify-content: center;
}

.crm-menu-point.active {
    background-color: #f2f2f7;
    color: #000000;
}

.crm-menu-point.active>.menu-icon {
    color: #4d68eb;
}

.crm-menu-point:hover {
    background-color: #e6e6eb;
}