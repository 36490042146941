/* @tailwind base; */
@tailwind components;
@tailwind utilities;
/* @import '~antd/dist/antd.css'; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f7f7f7; */
  background-color: #ffffff;
  color: #1f1f30;
}

code:not([class]) {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  white-space: break-spaces;
  background-color: rgb(32, 32, 36);
  border-radius: 6px;
  color: #fff;
}

.loading-text {
  width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  border-right: 1px solid black;
  animation: flash 1s step-end infinite, loading-text .3s steps(16);
}

#root {
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

/* Стили для тонкой полосы прокрутки */
div::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 2px;
}

/* Стили для фона тонкой полосы прокрутки */
div::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;

}

/* Стили для полосы прокрутки */
div::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 2px;
}

/* Стили для полосы прокрутки при наведении */
div::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 2px;
}

@keyframes flash {
  0% {
    border-color: black;
  }

  50% {
    border-color: transparent;
  }

  100% {
    border-color: black;
  }
}

@keyframes loading-text {
  from {
    width: 0;
  }
}

.main-menu {
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}

.main-menu .ant-drawer-header {
  color: white !important;
}

.main-menu .ant-drawer-title {
  color: white !important;
}

.main-menu .ant-drawer-close {
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

.main-menu .ant-drawer-close:hover {
  color: white !important;
}

.content-card {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem;
}

.icon-link {
  opacity: .6;
  cursor: pointer;
}

.icon-link:hover {
  opacity: 1;
}