.chat-message>* {
    margin-top: 2px;
    margin-bottom: 2px;
}

.chatgpt-menu-point {
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    color: #2c2c31;
    text-decoration: none;
    opacity: .8;
    border-radius: .5rem;
    margin-top: .25rem;
    margin-bottom: .25rem;
}

.chatgpt-menu-point>strong {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chatgpt-menu-point.active {
    background-color: #ececec;
    opacity: 1;
}

.chatgpt-menu-point.active {
    color: #3d6ab3;
}

.chatgpt-menu-point:hover {
    background-color: #e0e0e0;
    opacity: 1;
}

.chatgpt-menu-point>span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-message {
    word-break: break-word;
}